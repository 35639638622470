import React from "react"
import styled, { keyframes } from 'styled-components'
import media from '../../styles/media'
import { isMobile } from 'react-device-detect'

import Brand from './brand-head'

const aniArrow01 = keyframes`
  0% {
    transform:rotatez(-45deg) translateY(30px) translateX(30px);
  }
  100% {
    transform: rotatez(-45deg) translateY(-35px) translateX(-35px);
  }
`

const aniArrow02 = keyframes`
  0% {
    transform:translateX(45px);
  }
  100% {
    transform:translateX(-44px);
  }
`

const HeroCon = styled.div`
  width: 100vw;
  height: auto;
  color: white;
  background-color: #63bf60;
  border-top: 1px solid white;

  .talk, .contact, .address {
    border-bottom: 1px solid white;
  }
  .talk, .contact, .address, .social {
    padding: 40px 25px;
    h2 {
      font-weight: normal;
      font-size: 36px;
      line-height: 45px;
    }
    p, h2 {
      margin: 0;
      padding: 0;
      &.top {
        margin-bottom: 40px;
      }
    }
    a {
      color: white;
      text-decoration: none;
    }

    p {
      font-size: 16px;
      line-height: 30px;
    }
  }

  .talk {
    padding: 100px 25px 30px;
  }

  .social {
    p:nth-child(1) {
      margin-bottom: 2em;
    }
  }

  ${media.laptop`
    width: calc(45vw - 70px);
    margin-top: 79px;
    min-height: 120vh;
    height: auto;
    min-width: 500px;
    margin-left: 70px;
    border-right: 1px solid white;
    border-left: 1px solid white;
    padding-bottom: 0px;

    .talk, .contact, .address {
      border-bottom: 1px solid white;
    }
    .talk, .contact, .address, .social {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 30px;
      h2 {
        font-weight: normal;
        font-size: 50px;
        line-height: 1.5;
      }
      p, h2 {
        margin: 0;
        padding: 0;
        &.top {
          margin-bottom: 0;
        }
      }

      p {
        font-size: 16px;
        line-height: 1.25;
        a  {
          transition: color .3s ease;
          &:hover {
            color: #BCD6A6;
          }
        }
      }
    }


    a {
      .arrow{
        margin-top: 3px;
        position:relative;
        width:30px;
        height: 30px;
        cursor:pointer;
        transition:0.5s;
        overflow:hidden;
        transform: rotate(180deg);
      }
      .arrow:after{
        position:absolute;
        display:block;
        content:"";
        color:white;
        width: 20px;
        height: 15px;
        top: 1px;
        border-bottom:solid 2px;
        transform:translatex(4px);
      }

      .arrow:before{
        position:absolute;
        display:block;
        content:"";
        color:white;
        width: 8px;
        height: 8px;
        border-top:solid 2px;
        border-left:solid 2px;
        top:50%;
        left:2px;
        transform-origin:0% 0%;
        transform:rotatez(-45deg);
      }

      &:hover {
        .arrow{
          border:solid 1px white; 
          border-radius:50%;
        }
        .arrow:before{
          animation: ${aniArrow01} 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
        }

        .arrow:after{
          animation: ${aniArrow02} 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
          top: 0px;
        }
      }
    }
  `}

  ${media.laptopL`
    width: calc(55vw - 70px);
    margin-top: calc(10vh - 1px);
    min-height: 120vh;
    height: auto;
    min-width: 630px;
    margin-left: 70px;
    border-right: 1px solid white;
    border-left: 1px solid white;
    padding-bottom: 0px;

    .talk, .contact, .address {
      border-bottom: 1px solid white;
    }
    .talk, .contact, .address, .social {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 50px;
      h2 {
        font-weight: normal;
        font-size: 68px;
        line-height: 88px;
      }
      p, h2 {
        margin: 0;
        padding: 0;
        &.top {
          margin-bottom: 0;
        }
      }

      p {
        font-size: 20px;
        line-height: 28px;
        a  {
          transition: color .3s ease;
          &:hover {
            color: #BCD6A6;
          }
        }
      }
    }


    a {
      .arrow{
        margin-top: 3px;
        position:relative;
        width:30px;
        height: 30px;
        cursor:pointer;
        transition:0.5s;
        overflow:hidden;
        transform: rotate(180deg);
      }
      .arrow:after{
        position:absolute;
        display:block;
        content:"";
        color:white;
        width: 20px;
        height: 15px;
        top: 1px;
        border-bottom:solid 2px;
        transform:translatex(4px);
      }

      .arrow:before{
        position:absolute;
        display:block;
        content:"";
        color:white;
        width: 8px;
        height: 8px;
        border-top:solid 2px;
        border-left:solid 2px;
        top:50%;
        left:2px;
        transform-origin:0% 0%;
        transform:rotatez(-45deg);
      }

      &:hover {
        .arrow{
          border:solid 1px white; 
          border-radius:50%;
        }
        .arrow:before{
          animation: ${aniArrow01} 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
        }

        .arrow:after{
          animation: ${aniArrow02} 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
          top: 0px;
        }
      }
    }
  `}
  
`

const Hero = () => {

  return (
    <>
    {/* <div className="horizontal">
    <Brand />
    </div> */}
    <HeroCon>
      <div className="talk">
        <h2>Let's talk</h2>
      </div>
      <div className="contact">
        <p><a href="tel:0333869281">033 386 9281</a></p>
        <p><a href="mailto: sales@servworx.co.za">sales@servworx.co.za</a></p>
      </div>
      <div className="address">
        <p className="top">
          <a href="https://goo.gl/maps/TqGoEn62dfMXLGyt9" rel="noreferrer" target="_blank"> 
            21 Barnsley Road<br/>
            Camps Drift<br/>
            Pietermaritzburg<br/>
            2000<br/>
            <div class="arrow"></div>
            {isMobile && (
              <span>&rarr;</span>
            )}
          </a>
        </p>
        <p>
          <a href="https://goo.gl/maps/pUyuj7q4viCxyvWZA" rel="noreferrer" target="_blank">
            50 Herman Street<br/>
            Roete 24, Unit A4<br/>
            Meadowdale, Edenvale<br/>
            1400<br/>
            <div class="arrow"></div>
            {isMobile && (
              <span>&rarr;</span>
            )}
          </a>
        </p>
      </div>
      <div className="social">
        <p><a href="https://www.facebook.com/ServWorx" rel="noreferrer" target="_blank">Facebook</a></p>
        <p><a href="https://www.linkedin.com/company/servworxsa/" rel="noreferrer" target="_blank">LinkedIn</a></p>
      </div>

    </HeroCon>
    </>
  )
}

export default Hero
