import React, { useState } from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { Formik } from 'formik'
import emailjs from 'emailjs-com'

import Exclaim from '../../images/exclaim.webp'

const FormCon = styled.div`
  width: 100vw;
  height: auto;
  padding: 50px 25px;
  color: white;
  background-color: #63bf60;
  border-top: 1px solid white;

  form {
    input, textarea {
      background-color: transparent;
      border: 1px solid white;
      color:  white;
      width: 85vw;
      height: 40px;
      resize: none;
      border-radius: 0;
      -webkit-appearance: none;
      background-color: #63bf60;
      padding: 5px;
      &:focus {
        outline: none;
        border: 1px solid #BCD6A6;
      }
    }
    textarea {
      height: 80px;
    }
    button {
      width: 85vw;
      background-color: white;
      border: none;
      color: #63bf60;
      height: 40px;
      font-size: 15px;
      line-height: 30px;
      text-transform: uppercase;
      &.sent {
        background-color:#404040;
        color: white;
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus {
      border: 1px solid white;
      background-color: #63bf60;
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0px 1000px #63bf60 inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

   .success {
      font-size: 15px;
      line-height: 30px;
      letter-spacing: 0.02em;
    }
    .error {
      font-size: 11px;
      line-height: 13px;
      display: flex;
      align-items: flex-end;
      margin-top: -20px;
      margin-bottom: 20px;
      img {
        width: 10px;
        height: 10px;
        margin: 2px 0 0;
      }
    }

  ${media.laptop`
    width: 35vw;
    min-width: 400px;
    margin-top: calc(10vh - 1px);
    min-height: 100vh;
    height: auto;
    padding: 8vh 50px 0;

    form {
      .input-container {
        margin-bottom: 1rem;
      }
      input, textarea {
        padding: 0 20px;
        color: white;
        width: 35vw;
        height: 40px;
        margin: 0;
        transition: border .5s ease;
        &:focus {
          outline: none;
          border: 1px solid #BCD6A6;
        }
      }
      textarea {
        height: 80px;
        padding-top: 8px;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus {
        border: 1px solid white;
        -webkit-text-fill-color: white;
        -webkit-box-shadow: 0 0 0px 1000px #63bf60 inset;
        transition: background-color 5000s ease-in-out 0s;
      }
      button {
        width: 35vw;
        font-size: 14px;
        height: 50px;
        margin: 0 0 30px;
        transition: all .5s ease;
        &:hover  {
          background-color: #BCD6A6;
          color: white;
        }
      }
    }

    .success {
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.02em;
    }
    .error {
      font-size: 11px;
      line-height: 13px;
      display: flex;
      align-items: flex-end;
      margin-top: -20px;
      margin-bottom: 15px;
      img {
        width: 12px;
        height: 12px;
        margin: 4px 0 0;
      }
    }
  `}

  ${media.laptopL`
    width: 45vw;
    min-width: 400px;
    margin-top: 79px;
    min-height: 100vh;
    height: auto;
    padding: 8vh 50px 0;

    form {
      .input-container {
        margin-bottom: 1.45rem;
      }
      input, textarea {
        padding: 0 20px;
        color: white;
        width: 35vw;
        height: 50px;
        margin: 0;
        transition: border .5s ease;
        &:focus {
          outline: none;
          border: 1px solid #BCD6A6;
        }
      }
      textarea {
        height: 100px;
        padding-top: 8px;
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus {
        border: 1px solid white;
        -webkit-text-fill-color: white;
        -webkit-box-shadow: 0 0 0px 1000px #63bf60 inset;
        transition: background-color 5000s ease-in-out 0s;
      }
      button {
        width: 35vw;
        font-size: 14px;
        height: 50px;
        margin: 0 0 30px;
        transition: all .5s ease;
        &:hover  {
          background-color: #BCD6A6;
          color: white;
        }
      }
    }

    .success {
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.02em;
    }
    .error {
      font-size: 11px;
      line-height: 13px;
      display: flex;
      align-items: flex-end;
      margin-top: -20px;
      margin-bottom: 20px;
      img {
        width: 16px;
        height: 16px;
        margin: 2px 0 0;
      }
    }
  `}
`

const Form = () => {
  const [showFormSuccess, setFormSuccess] = useState(false)

  return (
    <FormCon>
      <Formik
        initialValues={{ name: "", email: "", contact: "", company: "", message: "" }}
        validate={values => {
          let errors = {}
          if (!values.name) {
            errors.name = "This name field is required"
          }
          if (!values.email) {
            errors.email = "This email field is required"
          }
          if (!values.company) {
            errors.company = "This company field is required"
          }
          if (!values.contact) {
            errors.contact = "This phone field is required"
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false)
          resetForm({})
          setFormSuccess(true)
          setTimeout(function(){
            setFormSuccess(false)
          }, 5000);
          resetForm({})
          emailjs.send(
            "service_qk7juwh",
            'template_oqfuue9',
            {
              from_name: values.name,
              from_email: values.email,
              company: values.company,
              contact: values.contact,
              message: values.message,
            },
            'user_BANVUnROwc7ElTyUBLnJW'
          )
          resetForm({})
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="details">
              <p className="input-container">
                <label for="name" htmlFor="name" unselectable="on">Name *</label><br/>
                <input 
                  id="name-form"
                  className="name"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name} 
                />
              </p>
              {errors.name && touched.name && (
                <p className="error"><img src={Exclaim} alt="exclaim" /> &nbsp;{errors.name}</p>
              )}
              <p className="input-container">
                <label for="company" htmlFor="company" unselectable="on">Company *</label><br/>
                <input 
                  id="name-form"
                  className="company"
                  type="text"
                  name="company"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.company} 
                />
              </p>
              {errors.company && touched.company && (
                <p className="error"><img src={Exclaim} alt="exclaim" /> &nbsp;{errors.company}</p>
              )}
              <p className="input-container">
                <label for="contact" htmlFor="contact" unselectable="on">Phone *</label><br/>
                <input 
                  id="name-form"
                  className="conatact"
                  type="text"
                  name="contact"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contact} 
                />
              </p>
              {errors.contact && touched.contact && (
                <p className="error"><img src={Exclaim} alt="exclaim" /> &nbsp;{errors.contact}</p>
              )}
              <p className="input-container">
                <label className="email" for="email" htmlFor="email" unselectable="on">Email *</label><br/>
                <input 
                  id="email-form"
                  className="email"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </p>
              {errors.email && touched.email && (
                <p className="error"><img src={Exclaim} alt="exclaim" /> &nbsp;{errors.email}</p>
              )}
            </div>
            <p className="input-container">
              <label for="message" htmlFor="message" unselectable="on">Description</label><br/>
              <textarea 
                className="message"
                type="text"
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
              />
              </p>
            {!showFormSuccess && (
              <button type="submit" disabled={isSubmitting} id="sendBtn">
                Send Request
              </button>
            )}
            {showFormSuccess && (
              <button type="submit" disabled className="sent">
                REQUEST  SUCCESSFULLY SENT
              </button>
            )}
          </form>
        )}
      </Formik>
    </FormCon>
  )
}

export default Form
