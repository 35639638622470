import React from "react"
import SEO from '../components/seo'

import ContactPage from "../components/ContactPage"

const SecondPage = () => (
  <>
    <SEO 
      title="Contact Us" 
      description="Contact ServWorx for all your workwear rental and laundry services."
      keywords={[`contact servworx`, `rental and laundry`, `customised and branded workwear`]} 
    />
    <ContactPage />
  </>
)

export default SecondPage
