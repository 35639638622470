import React from "react"
import Brand from "./brand-head"
import Hero from "./hero-contact"

import Form from "./form"
import { isMobile } from "react-device-detect"

const ContactPage = () => {
  const child   = { width: `auto`, height: `120vh`, backgroundColor: '#63bf60' }
  const parent  = { width: `100vw`, height: `120vh`, backgroundColor: '#63bf60', display: `flex`, overflow: `scroll` }

  return (
    <>
    <div className="horizontal">
      <div className="panel">
        <Brand />
      </div>
      <div style={parent} >
        <div className="panel">
          <Hero style={child} />
        </div>
        <div className="panel">
          <Form style={child} />
        </div>
      </div>
    </div>
  
    <div className="vertical">
      <Hero />
      <Form />
    </div>
    </>
  )
}

export default ContactPage
