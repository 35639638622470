import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'

import BrandMark from '../../images/brand.webp'

const BrandCon = styled.div`
  width: 100vw;
  height: 7vh;
  color: white;
  background-color: #63bf60;
  position:absolute;
  img {
    height: 3vh;
    margin: 2vh 25px;
  }

  ${media.laptop`
  border-left: 1px solid white;
  border-bottom: 1px solid white;
    min-width: calc(100vw - 70px);
    left: 70px;
    height: 80px;
    img {
      height: 30px;
      margin: 27px 50px 0;
    }
  `}

  ${media.laptopL`

  `}
  
`

const Brand = () => {

  return (
    <BrandCon>
      <img src={BrandMark} alt="brandmark" />
    </BrandCon>
  )
}

export default Brand
